<template>
    <div class="a-client-overview">
        <div class="bg-white-pure w-full border-b-2 border-grey-5">
            <div class="container flex flex-col p-0">
                <Header :client="client" :high-alerts="highAlerts" :editable="hasAnyEpisodes" />
                <v-tabs v-if="hasAnyEpisodes" v-model="selectedTab">
                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :to="{name: tab.route, params: {id: clientId}}"
                    >
                        {{ tab.text }}
                    </v-tab>
                </v-tabs>
            </div>
        </div>
        <div v-show="hasAnyEpisodes" class="container flex flex-col p-0">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <div v-show="!hasAnyEpisodes" class="container flex flex-col p-0">
            <div class="bg-white-pure l-box-shadow rounded px-4 py-3 mt-8 w-500 m-auto">
                <h4 class="font-display font-semibold text-center text-lg mb-4">No Access!</h4>
                <img
                    src="@/assets/images/no-access.png"
                    alt=""
                    class="w-240 m-auto block mb-2 mt-2"
                />
                <div class="text-center text-sm">
                    You don't have access to any episodes for this client! <br />If you need access
                    to this client please contact your manager.
                </div>
                <div class="text-center mt-4 mb-2">
                    <v-btn
                        depressed
                        data-cy="createEpisode"
                        class="v-btn--flat inline"
                        color="success"
                        value="submit"
                        @click.prevent="createEpisode"
                    >
                        <fa-icon icon="plus" class="mr-1" />
                        New Episode
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapStores} from 'pinia'
import camelcaseKeys from 'camelcase-keys'
import {mapState} from 'vuex'
import Header from '@/components/shared/Header'
import GET_CLIENT from '@/graphql/queries/getClient.gql'
import GET_HIGH_ALERTS from '@/graphql/queries/getHighAlerts.gql'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import OVERVIEW_TABS from '@/json/client-overview.tabs.json'
import {useUserClientStore} from '@/stores/useUserClientStore'
import {useModalStore} from '@/stores/useModalStore'

export default {
    name: 'ClientOverview',
    components: {Header},
    mixins: [ClientHelpers],
    data() {
        return {
            client: {},
            highAlerts: {},
            selectedTab: 0,
            tabs: OVERVIEW_TABS //  {text: 'Appointments', route: 'appointments'} // Commenting appointments as we might not include it in the first release
        }
    },
    apollo: {
        client: {
            query: GET_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return !this.clientId
            },
            update: ({client}) => {
                return camelcaseKeys(client, {deep: true})
            },
            result: function ({data: {client}}) {
                if (!client || !Object.keys(client).length) {
                    return this.$router.push({name: 'client-list'})
                }
                this.userClientStore?.updateCurrentClient(camelcaseKeys(client, {deep: true}))
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        highAlerts: {
            query: GET_HIGH_ALERTS,
            variables() {
                const locationProgramsCompare = {
                    _in: this.loggedInUser.locationProgramIds
                }
                return {
                    clientId: Number(this.clientId),
                    level: String('HIGH_ALERT'),
                    locationProgramsCompare
                }
            },
            skip() {
                return !this.clientId
            },
            result({data: {highAlerts}}) {
                this.highAlerts = camelcaseKeys(highAlerts, {deep: true})
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    methods: {
        createEpisode() {
            this.modalStore.openModal({
                modalName: 'ModalNewEpisode',
                payload: {
                    name: this.getFullName(this.client),
                    clientId: this.client.clientId,
                    keepOpen: true
                }
            })
        }
    },
    computed: {
        ...mapStores(useUserClientStore, useModalStore),
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices,
            clientId: (state) => state.app.clientId,
            currentEpisode: (state) => state.app.currentEpisode
        }),
        hasAnyEpisodes() {
            return !!this.currentEpisode?.episodeId
        }
    }
}
</script>
