<template>
    <div class="profile-avatar rounded rounded-full mr-6 flex items-center justify-center">
    <!-- use below when implementing photo upload--> 
    <!-- add class ="cursor-pointer" + @click.prevent="clientProflilePicture">-->
        <img src="@/assets/images/empty-user.png" alt v-if="showImage" />
        <img src="@/assets/images/empty-user.png" class="w-full rounded-full" alt v-else />
    </div>
</template>

<script>
export default {
    name: 'ProfileAvatar',
    props: {
        userObj: {
            type: String,
            default: ''
        },
        showImage: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        clientProflilePicture() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                name: 'test',
                keepOpen: true,
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalClientProfile')
        }
    },
    computed: {
        imgPath() {
            // TODO: get image based on profile
            return this.showImage ? '' : '' 
        }
    },
}
</script>
